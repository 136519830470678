import React from "react"
import useTranslations from "../../../utils/useTranslations"

const LegalEULABittorrentClassicPro = ({ pageContext: {text} }) => {

const t = useTranslations(text)

  return (
    <div className="legal container my-5 py-5">
      <h1>{t("End User License Agreement (EULA)")}</h1>
      <h2>{t("µTorrent Classic Pro and/or BitTorrent Classic Pro")}</h2>
      <hr style={{ margin: `2rem 0` }} />

<div id="main">
<div class="content clearfix">
    <p><strong>IMPORTANT:</strong> PLEASE READ THE TERMS AND CONDITIONS OF THIS END USER LICENSE AGREEMENT ("LICENSE AGREEMENT") CAREFULLY BEFORE USING THE SOFTWARE (AS DEFINED BELOW). BITTORRENT LIMITED ("BITTORRENT", "THE COMPANY", "WE", or "OUR") IS WILLING TO LICENSE THE SOFTWARE TO YOU AS THE INDIVIDUAL, THE COMPANY, OR THE LEGAL ENTITY THAT WILL BE UTILIZING THE SOFTWARE (REFERENCED BELOW AS "YOU" OR "YOUR") ONLY ON THE CONDITION THAT YOU ACCEPT ALL OF THE TERMS OF THIS LICENSE AGREEMENT. THIS IS A LEGAL AND ENFORCEABLE CONTRACT BETWEEN YOU AND BITTORRENT LIMITED. BY CLICKING THE "I AGREE" OR "YES" BUTTON OR OTHERWISE INDICATING ASSENT ELECTRONICALLY, OR INSTALLING THE SOFTWARE, YOU AGREE TO THE TERMS AND CONDITIONS OF THIS LICENSE AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THESE TERMS AND CONDITIONS, CLICK THE "I DO NOT AGREE", "CANCEL" OR "NO" OR "CLOSE WINDOW" BUTTON OR OTHERWISE INDICATE REFUSAL, DO NOT DOWNLOAD, ORDER, OPEN, INSTALL OR USE THE SOFTWARE, AND CONTACT YOUR VENDOR AT support@payproglobal.com OR OUR CUSTOMER SERVICE, USING THE CONTACT DETAILS IN SECTION 10 OF THIS LICENSE AGREEMENT, FOR INFORMATION ON HOW TO OBTAIN A REFUND OF THE MONEY YOU PAID FOR THE SOFTWARE (LESS SHIPPING, HANDLING, AND ANY APPLICABLE TAXES EXCEPT IN CERTAIN STATES AND COUNTRIES WHERE SHIPPING, HANDLING, AND TAXES ARE REFUNDABLE) AT ANY TIME DURING THE THIRTY (30) DAY PERIOD FOLLOWING THE DATE OF PURCHASE. IN ADDITION, THE SOFTWARE INCORPORATES AN ANTI-VIRUS APPLICATION. USE OF THE ANTI-VIRUS APPLICATION IS SUBJECT TO CERTAIN TERMS THAT ARE INCORPORATED BELOW. LICENSEE ALSO CONSENTS TO OUR USE OF THE E-MAIL ADDRESS PROVIDED BY LICENSEE TO COMMUNICATE WITH LICENSEE ABOUT THE SOFTWARE AND ANY FUTURE VERSIONS OR VARIATIONS THEREOF, INCLUDING BUT NOT LIMITED TO INFORMATION ABOUT A RENEWAL LICENSE IN CONNECTION WITH THE EXPIRATION OR TERMINATION OF THE SERVICE PERIOD HEREUNDER.</p>

         <h3>1. License</h3>
            <p>The software (including all its accompanying features and services) and any software documentation (the "Documentation") that accompanies this License Agreement (collectively the "Software") is the property of BitTorrent Limited or its licensors, and is protected by copyright law. Although BitTorrent Limited continues to own the Software, after Your acceptance of this License Agreement, You will have certain rights to use the Software during the Service Period under the terms of this License Agreement. The "Service Period" shall begin on the date of Your initial installation of a copy of the Software, and shall last for the period of time for which you have fully paid for the subscription, as set forth in the applicable transaction documentation from the authorized distributor or reseller from which You obtained the Software, subject to the terms of this License Agreement. The Software may automatically deactivate and become non-operational at the end of the Service Period, and You will not be entitled to receive any feature or content updates to the Software unless the Service Period is renewed.</p>
<p>This License Agreement governs any releases, revisions, updates or enhancements to the Software that The Company may make available to You. Except as may be modified by the Documentation, and subject to The Company’s right to terminate for Your breach pursuant to Section 10, Your rights and obligations under this License Agreement with respect to the use of this Software are as follows.</p>
<p>During the Service Period, You may:</p>
<p>A. use one copy of the Software on a single computer;<br /> 
B. make one copy of the Software for back-up or archival purposes, or copy the Software onto the hard disk of Your computer and retain the original for back-up or archival purposes; and <br />
C. use the Software on a network, provided that You have a licensed copy of the Software for each computer that can access the Software over that network.</p>
<p>You may not, nor may You permit any other person to:</p>
<p>A. sublicense, rent, lease or transfer any portion or all of the Software; <br />
B. reverse engineer, decompile, disassemble, modify, translate, make any attempt to discover the source code of the Software or create derivative works from the Software;<br />
C. use the Software as part of a facility management, timesharing, service provider or service bureau arrangement; or<br /> 
D. use the Software in any manner that is not permitted pursuant to this License Agreement. 
</p>
            <h3>2. Software Feature and Content Updates:</h3>
            <p>A. You shall have the right to receive new features to the Software as The Company, in its sole discretion, makes such features available with the Software during Your Service Period. The Company continually strives to improve the usability and performance of its products and services. In order to optimize the Software The Company may, at its discretion and without notice, add, modify or remove features from the Software at any time. </p>

            <h3>3. Product Installation; Required Activation:</h3>
                    <p>There may be technological measures in this Software that are designed to prevent unlicensed or illegal use of the Software. You agree that BitTorrent Limited may use these measures to protect The Company against software piracy. This Software may contain enforcement technology that limits the ability to install and uninstall the Software on a computer to not more than a finite number of times for a finite number of computers. This License Agreement and the Software containing enforcement technology may require activation. If so, the Software will only operate for a finite period of time prior to Software activation by You. During activation, You may be required to provide Your unique activation code accompanying the Software and computer configuration in the form of an alphanumeric code over the Internet to verify the authenticity of the Software. If You do not complete the activation within the finite period of time, or as prompted by the Software, the Software will cease to function until activation is complete; at which time the Software functionality will be restored. In the event that You are not able to activate the Software over the Internet, or through any other method specified during the activation process, You may contact Our Customer Support using the information provided by The Company during activation, or as set forth below. </p>
            <h3>4. Technical Support:</h3>
                    <p>In connection with Your use of the Software, You may choose to access certain technical support features that may be offered, which may include e-mail support (any such technical support shall be referred to in this License Agreement as the "Technical Support"). Any such Technical Support shall be provided in The Company’s sole discretion without any guarantee or warranty of any kind. It is solely Your responsibility to complete a backup of all Your existing data, software and programs before receiving any Technical Support. In the course of providing the Technical Support, The Company may determine that the technical issue is beyond the scope of the Technical Support. The Company reserves the right to refuse, suspend or terminate any of the Technical Support in its sole discretion.</p>
            <h3>5. Privacy; Data Protection:</h3>
            <p>From time to time, the Software may collect certain information from the computer on which it is installed, which may include:</p>
			<p><ul>
				<li>Status information regarding installation and operation of the Software. This information indicates to The Company whether installation of the Software was successfully completed as well as whether the Software has encountered an error. The status information could contain personally identifiable information only if such information is included in the name of the file or folder encountered by the Software at the time of installation or error. The status information may be collected by The Company for the purpose of evaluating and improving product performance and installation success rate. We may also use this information to optimize its web-pages.</li>
				
				<li>Information contained in a report that You may choose to send to The Company through the Software when the Software encounters a problem. The report includes information regarding the status of both the Software and Your computer at the time that the Software encountered the problem. The status information about Your computer may include the system language, country locale, and the operating system version for Your computer, as well as the processes running, their status and performance information, and data from files or folders that were open at the time the Software encountered the problem. The information could contain personally identifiable information if such information is included in, or is a part of the name of the files or folders open at the time the Software encountered the problem. This information will be sent to The Company only with Your permission and will not be sent automatically. The information is collected for the purpose of correcting the encountered problem and improving product performance.</li>
			
				<li>The Internet Protocol (IP) address and/or Media Access Control (MAC) address and the Machine ID of the computer on which the Software is installed to enable the Software to function and for license administration purposes.</li>
				
				<li>Other general, statistical information used for product analysis, and for improving product functionality. </li>
			</ul></p>

			<p>Information may be transferred to the BitTorrent Limited group in the United States or other countries that may have less protective data protection laws than the region in which You are situated (including the European Union) and may be accessible by our employees or contractors exclusively to be used in accordance with the purposes described above. For the same purposes the information may be shared with partners and vendors that process information on behalf of The Company. The Company has taken steps so that the collected information, if transferred, receives an adequate level of protection.
Subject to applicable laws, The Company reserves the right to cooperate with any legal process and any law enforcement or other government inquiry related to your use of this Software. This means that The Company may provide documents and information relevant to a court subpoena or to a law enforcement or other government investigation.
            <h3>6. Limited Warranty</h3>
            <p>BitTorrent Limited warrants that any media manufactured by The Company on which the Software is distributed, if any, will be free from defects for a period of sixty (60) days from the date of delivery of the Software to You. Your sole remedy in the event of a breach of this warranty will be that The Company will, at its option, replace any defective media returned to The Company within the warranty period or refund the money You paid for the Software. BitTorrent Limited does not warrant that the Software will meet Your requirements or that operation of the Software will be uninterrupted or that the Software will be error-free. For the avoidance of doubt, references to "Software" in the foregoing sentence shall include, but not be limited to, Technical Support.</p>
			<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ABOVE WARRANTY IS EXCLUSIVE AND IN LIEU OF ALL OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS. YOU MAY HAVE OTHER RIGHTS, WHICH VARY FROM STATE TO STATE AND COUNTRY TO COUNTRY.</p>

            <h3>7. Disclaimer of Damages</h3>
            <p>SOME STATES AND COUNTRIES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE BELOW LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>

            <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND REGARDLESS OF WHETHER ANY REMEDY SET FORTH HEREIN FAILS OF ITS ESSENTIAL PURPOSE, IN NO EVENT WILL THE COMPANY OR ITS LICENSORS BE LIABLE TO YOU FOR ANY SPECIAL, CONSEQUENTIAL, INDIRECT, OR SIMILAR DAMAGES, INCLUDING ANY LOST PROFITS OR LOST DATA ARISING OUT OF THE USE OR INABILITY TO USE THE SOFTWARE (INCLUDING BUT NOT LIMITED TO USE OF TECHNICAL SUPPORT) EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

            <p>IN NO CASE SHALL THE COMPANY’S OR ITS LICENSORS’ LIABILITY EXCEED THE PURCHASE PRICE WHICH YOU PAID FOR THE APPLICABLE SERVICE PERIOD. The disclaimers and limitations set forth above will apply regardless of whether You accept the Software. </p>
            <h3>8. U.S. Government Restricted Rights</h3>
            <p>For U.S. Government procurements, the Software is deemed to be commercial computer software as defined in FAR 12.212 and subject to restricted rights as defined in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights" and DFARS 227.7202, "Rights in Commercial Computer Software or Commercial Computer Software Documentation", as applicable, and any successor regulations. Any use, modification, reproduction release, performance, display or disclosure of the Software by the U.S. Government shall be solely in accordance with the terms of this License Agreement.</p>

            <h3>9. Export Regulation</h3>
            <p>You acknowledge that the Software and related technical data and services (collectively "Controlled Technology") may be subject to the import and export laws of the United States, specifically the U.S. Export Administration Regulations (EAR), and the laws of any country where Controlled Technology is imported or re-exported. You agree to comply with all relevant laws and will not export any Controlled Technology in contravention to U.S. law nor to any prohibited country, entity, or person for which an export license or other governmental approval is required. All BitTorrent Limited product is prohibited for export or re-export to Cuba, North Korea, Iran, Syria and Sudan and to any country subject to relevant trade sanctions. USE OR FACILITATION OF THE COMPANY'S PRODUCT IN CONNECTION WITH ANY ACTIVITY INCLUDING, BUT NOT LIMITED TO, THE DESIGN, DEVELOPMENT, FABRICATION, TRAINING, OR TESTING OF CHEMICAL, BIOLOGICAL, OR NUCLEAR MATERIALS, OR MISSILES, DRONES, OR SPACE LAUNCH VEHICLES CAPABLE OF DELIVERING WEAPONS OF MASS DESTRUCTION IS PROHIBITED, IN ACCORDANCE WITH U.S. LAW.</p>
            <h3>10. General</h3>
            <p>This License Agreement will be governed by the laws of the State of California, United States of America. This License Agreement is the entire agreement between You and The Company relating to the Software and: (i) supersedes all prior or contemporaneous oral or written communications, proposals, and representations with respect to its subject matter; and (ii) prevails over any conflicting or additional terms of any quote, order, acknowledgment, or similar communications between the parties. Notwithstanding the foregoing, nothing in this License Agreement will diminish any rights You may have under existing consumer protection legislation or other applicable laws in Your jurisdiction that may not be waived by contract. The Service Period and this License Agreement shall terminate upon Your breach of any term contained in this License Agreement and You shall cease use of and destroy all copies of the Software and Documentation. The disclaimers of warranties and damages and limitations on liability shall survive termination. This License Agreement may only be modified by the Documentation or by a written document that has been signed by both You and The Company. Should You have any questions concerning this License Agreement, or if You desire to contact BitTorrent Limited for any reason, please write to BitTorrent Limited Customer Service, Geneva Place, Waterfront Drive, P.O. Box 3469, Road Town, Tortola, British Virgin Islands or visit the Support Center page at http://help.utorrent.com, in the case of µTorrent Classic Pro/Plus, or http://help.bittorrent.com, in the case of BitTorrent Classic Pro/Plus.</p>

<br />


			<div class="thickhr"></div>

<br />

</p>
</div></div>
    </div>
  )
}

export default LegalEULABittorrentClassicPro